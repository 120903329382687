<template>
    <div class="main_con" :style="{ minHeight: `${height}px` }">
        <div class="replace_search flex_column_center_center">
            <div class="title1 flex_row_center_center">
                <img :src="configInfo.main_site_logo" @click="goHome" />
                <div>{{L['国产替代']}}</div>
            </div>
            <div class="search1 flex_column_center_center">
                <div class="sld_seach_box1">
                    <form class="form flex_row_start_center" @submit.prevent>
                        <el-select class="form_select" v-if="categories.data.length > 0" v-model="categoriesVal"
                            :placeholder="L['全部分类']" @change="elChange" clearable>
                            <el-option v-for="item in categories.data" :key="item.id" :label="item.categoryName"
                                :value="item.categoryId">
                            </el-option>
                        </el-select>
                        <el-select class="form_select" v-else :placeholder="L['全部分类']" clearable
                            v-model="categoriesVal"></el-select>
                        <div class="search_line"></div>
                        <input v-model="keyword" type="text" class="text" autocomplete="off"
                            style="color:rgb(153,153,153);" :placeholder="L['请输入关键词']" ref="searchInputCart"
                            @input="searchAs" @blur="showAs = false" />
                        <input type="submit" :value="L['搜索']" class="button" @mousedown="search" />
                    </form>
                </div>
                <div v-if="searchList.length && keyword && showAs" id="searchA" class="search_association"
                    :style="{ height: (searchList.length <= 5) ? (searchList.length * 46 + 'px') : '275px' }">
                    <el-scrollbar>
                        <div class="s_a_item" v-for="(item, index) in searchList" :key="index"
                            @mousedown="quickSearch(item)">
                            <!-- 因为click事件与blur事件冲突，blur事件优先，于是换成mousedown -->
                            <div class="txt_col1">{{ item.foreignSpec }}</div>
                            <div class="txt_col1 txt_col2">{{ item.foreignFactory }}</div>
                            <div class="txt_col1 txt_col3">{{ item.goodsNum }}{{L['个产品']}}</div>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="search_key" v-if="reHis.length">
                    <div class="txt1">{{L['搜索历史：']}}</div>
                    <div class="word_set1 flex_row_start_center">
                        <div class="word1" v-for="(item, index) in reHis" :key="index" @click="quickSearch(item, 'h')">
                            {{ item.foreignSpec }}
                        </div>
                    </div>
                </div>
                <div class="search_key">
                    <div class="txt1">{{L['热门搜索：']}}</div>
                    <div class="word_set1 flex_row_start_center">
                        <div class="word1" v-for="(item, index) in hotList" :key="index" @click="toReplaceList(item)">
                            {{ item }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getCurrentInstance, onMounted, reactive, ref, provide } from "vue";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
export default {
    setup() {
        const { proxy } = getCurrentInstance();
        const router = useRouter()
        const store = useStore()
        const configInfo = ref(store.state.configInfo);

        // 获取分类
        const categories = reactive({ data: [] })
        const L = proxy.$getCurLanguage();
        const keyword = ref('')
        const categoriesVal = ref('')
        const cateId = ref(0)
        const reHis = ref([])
        const searchList = ref([])
        const hotList = ref([])
        const height = ref(0)
        const showAs = ref(false)
        const getCategories = () => {
            proxy.$get('v3/goods/front/goods/category/topCategory').then(res => {
                if (res.state == 200) {
                    categories.data = res.data
                }
            })
        };
        const searchAs = async () => {

            if (!keyword.value) {
                return
            }


            let param = {
                current: 1,
                pageSize: 50,
                keyword: keyword.value
            }


            if (cateId.value) param.categoryId1 = cateId.value
            showAs.value = false
            searchList.value = [];
            await proxy.$get('v3/goods/front/goods/foreign/list', param).then(res => {
                if (res.state == 200) {
                    searchList.value = res.data.list
                }
                showAs.value = true
            })
        }

        const search = () => {

            if (!keyword.value.trim()) {
                return
            }


            let query = {
                replaceKeyword: keyword.value.trim()
            }


            let hisUnit = {
                foreignSpec: keyword.value.trim(),
                foreignId: 0,
                categoryId: cateId.value || 0
            }

            if (categoriesVal.value) {
                query.categoryId = cateId.value
            }


            setHistoryData(hisUnit, false)
            let newWin = router.resolve({
                path: '/prototype/replacement/list',
                query
            })
            window.open(newWin.href, '_blank')
        }

        const quickSearch = (i, h) => {

            let query = {}
            if (i.foreignId != '0') {
                query.kId = i.foreignId
            }

            if (i.categoryId1) {
                query.categoryId = i.categoryId1
            }
            query.replaceKeyword = i.foreignSpec

            setHistoryData(i, h)
            let newWin = router.resolve({
                path: '/prototype/replacement/list',
                query
            })
            window.open(newWin.href, '_blank')
        }

        const getHistoryList = () => {
            let history_data = localStorage.getItem('re_his');
            if (history_data) {
                let his_array = history_data.split("~");
                reHis.value = his_array.map(item => {
                    let obj = {
                        foreignSpec: item.split(':')[0],
                        foreignId: item.split(':')[1],
                        categoryId: item.split(':')[2],
                        timeStamp: item.split(':')[3]
                    }
                    return obj
                }).sort((a, b) => b.timeStamp - a.timeStamp);

            } else {
                reHis.value = []
            }
        }

        const elChange = (e) => {
            cateId.value = e
        }

        //设置缓存
        const setHistoryData = (item, h) => {
            if (h) {
                item.timeStamp = new Date().getTime()
            }

            let tmp_data = [...reHis.value];
            tmp_data.unshift({
                ...item,
                timeStamp: new Date().getTime()
            });
            reHis.value = filterSame(tmp_data).sort((a, b) => b.timeStamp - a.timeStamp).slice(0, 14);


            let stringifyItem = reHis.value.map(i => `${i.foreignSpec}:${i.foreignId}:${i.categoryId}:${i.timeStamp}:h`)


            let history_val_str = stringifyItem.join('~');

            localStorage.setItem("re_his", history_val_str)
        }

        // 过滤不重复的数据
        const filterSame = (tmp_data) => {
            const obj = {}
            const objZero = {}
            let zData = tmp_data.filter(i => i.foreignId == 0)
            let nzData = tmp_data.filter(i => i.foreignId != 0)


            zData.forEach(item => {
                obj[item.foreignSpec] = item
            })

            nzData.forEach(item => {
                obj[item.foreignId] = item
            })

            let objlist = Object.keys(obj).map(function (group) {
                return obj[group];
            });

            let objZeroList = Object.keys(objZero).map(function (group) {
                return obj[group];
            });

            return [...objlist, ...objZeroList]
        }

        const getHotWord = () => {
            proxy.$get("v3/system/front/setting/getSettings?names=replace_hot_search_words").then((res) => {
                if (res.state == 200) {
                    let tmp_data = res.data[0].split(',')
                    hotList.value = tmp_data;
                }
            });
        };

        const toReplaceList = (item) => {

            let query = {
                replaceKeyword: item
            }

            let hisUnit = {
                foreignSpec: item,
                foreignId: 0,
                categoryId: 0
            }
            setHistoryData(hisUnit, false)
            let newWin = router.resolve({
                path: '/prototype/replacement/list',
                query
            })
            window.open(newWin.href, '_blank')
        }

        const goHome = () => {
            router.push(`/`)
        }

        onMounted(() => {
            getCategories()
            getHistoryList()
            getHotWord()
            height.value = document.documentElement.clientHeight || document.body.clientHeight
        })

        return {
            getCategories,
            categories,
            L,
            keyword,
            searchAs,
            searchList,
            search,
            quickSearch,
            elChange,
            categoriesVal,
            reHis,
            hotList,
            toReplaceList,
            height,
            showAs,
            goHome,
            configInfo
        }
    }
}
</script>

<style lang="scss">
.main_con {

    .replace_search {
        width: 1240px;
        margin: 0 auto;
        padding-top: 170px;
        padding-bottom: 50px;
        background: #fff;

        .title1 {
            line-height: 38px;
            color: #333333;
            font-family: SourceHanSansCN-Medium;
            font-weight: 500;
            text-align: center;

            img {
                width: 106px;
                height: 60px;
                cursor: pointer;
            }

            div {
                position: relative;
                line-height: 38px;
                color: #000000;
                font-size: 28px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                margin-left: 30px;
                padding-left: 30px;

                &::before {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -17.5px;
                    z-index: 9;
                    width: 1px;
                    height: 35px;
                    background: #D9D9D9;
                }
            }
        }

        .search1 {
            position: relative;

            .sld_seach_box1 {
                width: 800px;
                height: 50px;
                background: $colorMain;
                border: 2px solid $colorMain;
                border-radius: 9px;
                overflow: hidden;
                margin-top: 60px;
                margin-bottom: 3px;
                position: relative;
                z-index: 11;

                .form {
                    // background-color: $colorMain;
                    height: 46px;
                    overflow: hidden;

                    .search_line {
                        position: absolute;
                        left: 115px;
                        width: 2px;
                        height: 28px;
                        background-color: $colorI;
                    }

                    .form_select {
                        width: 120px;

                        .el-input {
                            display: inline-block;
                        }

                        .el-input__inner {
                            width: 112px;
                            padding-left: 10px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            border-radius: 0;
                        }

                        .el-input__suffix {
                            top: 4px;
                            right: 0;
                        }

                        .el-input input::-webkit-input-placeholder {
                            color: #333333;
                        }

                        .el-input input::-moz-input-placeholder {
                            color: #333333;
                        }

                        .el-input input::-ms-input-placeholder {
                            color: #333333;
                        }
                    }

                    .text {
                        height: 36px;
                        background-color: #fff;
                        width: 627px;
                        -webkit-appearance: none;
                        -webkit-border-radius: 0;
                        height: 46px;
                        padding: 5px 5px 5px 15px;
                        background-position: 0 -360px;
                        background-color: #fff;
                        background-repeat: repeat-x;
                        line-height: 20px;
                        font-family: arial, "\5b8b\4f53";
                        font-size: 16px;
                        outline: none;
                        border: none;
                    }

                    input {
                        margin: 0;
                        padding: 0;
                        height: 46px;
                        border: 0;
                    }

                    input::placeholder {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: rgb(153, 153, 153);
                    }

                    ::-webkit-input-placeholder {

                        margin-left: 20px;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: rgb(153, 153, 153);
                    }

                    /* 使用webkit内核的浏览器 */
                    :-moz-placeholder {

                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: rgb(153, 153, 153);
                    }

                    /* Firefox版本19+ */
                    :-ms-input-placeholder {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: rgb(153, 153, 153);
                    }

                    .button {
                        width: 103px;
                        background: $colorMain;
                        font-size: 16px;
                        font-weight: 600;
                        color: #fff;
                        float: right;
                        cursor: pointer;
                    }
                }

                .hot_search_wrap {
                    height: 30px;
                    line-height: 30px;
                    color: #999;
                    overflow: hidden;
                    white-space: nowrap;

                    strong {
                        float: left;
                        font-weight: 400;
                    }

                    a {
                        color: $colorTitle2;

                        &:link,
                        &:visited {
                            float: left;
                            margin-right: 10px;
                        }

                        &:hover {
                            color: $colorMain;
                        }
                    }
                }
            }

            .search_association {
                position: absolute;
                top: 118px;
                left: -0;
                z-index: 99;
                width: 702px;
                height: 270px;
                border: 2px solid $colorMain;
                background: #fff;
                border-radius: 8px;
                border-top-right-radius: 0;
                overflow: hidden;

                .el-scrollbar {
                    max-height: 270px;
                    overflow-y: auto;
                }

                .s_a_item {
                    display: flex;
                    justify-content: space-between;
                    overflow: hidden;
                    color: $colorTitle2;
                    font-size: 13px;
                    padding: 15px 20px;
                    -webkit-font-smoothing: antialiased;
                    cursor: pointer;

                    &:last-child {
                        border-bottom: none;
                    }

                    .txt_col1 {
                        width: 220px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        text-align: left;
                    }

                    .txt_col2 {
                        margin-left: 20px;
                        width: 200px;
                    }

                    .txt_col3 {
                        width: 150px;
                        text-align: right;
                    }

                    &:hover {
                        color: $colorMain;
                        background-color: #f7f7f7;
                    }
                }
            }

            .search_key {
                width: 800px;
                margin-top: 40px;

                .txt1 {
                    color: #333333;
                    font-size: 16px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    font-weight: bold;
                }

                .word_set1 {
                    flex-wrap: wrap;

                    .word1 {
                        max-width: 196px;
                        color: #817979;
                        height: 32px;
                        line-height: 18px;
                        font-size: 13px;
                        font-family: SourceHanSansCN-Regular;
                        margin-top: 15px;
                        margin-right: 20px;
                        padding: 7px 14px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        background: $colorC;
                        // border: 1px solid #F1F1F1;
                        border-radius: 16px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>